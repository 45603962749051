/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/Layout';
import Contact from '../components/global/Contact';
import Webform from '../components/global/Webform';
import SEO from '../components/SEO';
import HeroLandingPageAlt from '../components/layout/hero/HeroLandingPageAlt';
import useAuth from '../util/hooks/useAuth';
import AnimateOnEntrance from '../components/fx/animate/AnimateOnEntrance';
import { parsingFunctionArray } from '../util/helper/parsingFunction';
import Modal from '../components/global/Modal';
import { ScrollContainer } from '../context/scrollBehavior';
import RegisterSignUp from '../components/global/RegisterSignUp';
import { LeaseCalculator } from '../components/global/Lease';
import ImageInView from '../components/fx/animate/ImageInView';

const EstimateFormPopUp = ({ children }) => {
  const [form, setForm] = useState(false);

  const toggleEstimateFormModal = (e) => {
    setForm(!form);
  };

  return (
    <React.Fragment>
      <a
        className="estimate-form-link"
        role="button"
        tabIndex={0}
        onClick={() => toggleEstimateFormModal()}
        onKeyDown={() => toggleEstimateFormModal()}
      >
        {children}
      </a>

      <Modal active={form} onClose={() => toggleEstimateFormModal()}>
        <Webform name="Get a Free Estimate" />
      </Modal>
    </React.Fragment>
  );
};

const PagePage = ({ data, pageContext }) => {
  useAuth();
  const [device, setDevice] = useState('mobile');
  const [fillOutPopup, setFillOutPopup] = useState(false);
  const { title, bodyContent, relationships__field_banner } = pageContext;

  const subMenu = { title: 'about', extra: true };

  /*
        Use parsingFunction to replace <EstimateForm> </EstimateForm>  as a link for the pop up estimate form.
        parsingFunction checks the delimiter, then
        Use the parsingFunction to replace the delimiter to a link connected with a popup form
        Wrap the {children} of delimiter inside the link
        return {found, body}
    */
  const body = parsingFunctionArray({
    body: bodyContent,
    list: [
      {
        search: 'EstimateForm',
        Component: EstimateFormPopUp,
        children: true,
      },
      {
        search: 'LeaseCalculator',
        Component: LeaseCalculator,
        children: false,
      },
    ],
  });

  return (
    <Layout
      device={device}
      setDevice={setDevice}
      logo={data.logo}
      menu={data.allMenuMenuLink}
      footerMenu={data.footer_menu}
      subMenu={subMenu}
      locations={data.locations.edges}
    >
      <SEO title={title} />
      <div>
        {relationships__field_banner ? (
          <HeroLandingPageAlt
            img={relationships__field_banner}
            device={device}
            height={500}
          />
        ) : null}
        <section className="grid-section grid-content-container t-left info-page">
          <div className="t-heading-container">
            <h1
              className={`t-heading t-uppercase ${
                title === 'Contact' ? 't-center' : ''
              }`}
            >
              {title}
            </h1>
          </div>

          {title === 'Contact' ? (
            <Contact bodyContent={bodyContent} />
          ) : // Check if replacement is found
          body.found ? (
            <React.Fragment>
              <div className="t-content-container body-content t-left">
                {body.replacedBody.map((element, i) => {
                  if (React.isValidElement(element)) {
                    return element;
                  } else {
                    return (
                      <span
                        key={element + i}
                        dangerouslySetInnerHTML={{ __html: element }}
                      />
                    );
                  }
                })}
              </div>

              <ScrollContainer force={true}>
                <section className="grid-section callout-section grid-content-container t-center">
                  <div className="introAnimateBottomLeft">
                    <img src="/media/grid_bl.jpg" />
                  </div>
                  <div className="introAnimateTopRight">
                    <img src="/media/grid_tr.jpg" />
                  </div>
                  <AnimateOnEntrance className="fadeInUp" device={device}>
                    <button
                      className={'button btn-large btn-center btn-space-xlarge'}
                      device={device}
                      onClick={() => setFillOutPopup(true)}
                    >
                      Fill Out the free estimate
                    </button>
                    <Modal
                      active={fillOutPopup}
                      onClose={() => setFillOutPopup(false)}
                    >
                      <Webform name="Get a Free Estimate" />
                    </Modal>
                  </AnimateOnEntrance>
                </section>
              </ScrollContainer>
              <ScrollContainer force={true}>
                <section className="grid-section grid-content-container t-left set-up-account">
                  <div className="t-content-container t-left">
                    <div className="grid-2 grid-justify-center grid-align-center grid-gap-lg">
                      <div className="grid-col form-set-up-account">
                        <div className="t-heading-container">
                          <AnimateOnEntrance
                            className="fadeInLeft"
                            device={device}
                          >
                            <h3 className="t-mxlarge t-heading">
                              Become a member!
                            </h3>
                          </AnimateOnEntrance>
                          <div className="t-content-container t-left t-callout">
                            <AnimateOnEntrance
                              className="fadeInLeft"
                              device={device}
                            >
                              <p>
                                As a member of My Loch Gallery you can set up a collector profile, save artworks and be notified of new work by your favourite atists, access to our newsletter, and more.
                              </p>
                            </AnimateOnEntrance>
                          </div>
                        </div>
                        <AnimateOnEntrance
                          className="fadeInLeft"
                          device={device}
                        >
                          <RegisterSignUp />
                        </AnimateOnEntrance>
                      </div>
                      <div className="grid-col mobile-no">
                        <AnimateOnEntrance
                          className="fadeInRight"
                          device={device}
                        >
                          <ImageInView src={'/media/su_grid.jpg'} />
                        </AnimateOnEntrance>
                      </div>
                    </div>
                  </div>
                </section>
              </ScrollContainer>
            </React.Fragment>
          ) : (
            <div
              className="t-content-container body-content t-left"
              dangerouslySetInnerHTML={{ __html: bodyContent }}
            ></div>
          )}
        </section>
      </div>
    </Layout>
  );
};

export default PagePage;

export const PageQuery = graphql`
  query PageQuery {
    allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "gatsby-menu" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          drupal_parent_menu_item
          bundle
          drupal_id
          menu_name
        }
      }
    }
    footer_menu: allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "footer" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          bundle
          drupal_id
          menu_name
        }
      }
    }
    locations: allNodeLocation {
      edges {
        node {
          field_address {
            address_line1
            address_line2
            country_code
            locality
            postal_code
          }
          field_address_description
          field_address_short
          field_location_email
          field_location_fax_number
          field_location_phone_number
          title
          field_office_hours_description
        }
      }
    }
    footer_menu: allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "footer" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          bundle
          drupal_id
          menu_name
        }
      }
    }
  }
`;
