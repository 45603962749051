import { replace } from 'lodash';
import React from 'react';
import reactStringReplace from 'react-string-replace';

/**
 * Take a body string and replace with JSX
 * @param {Object} parseObject
 * @param {string} parseObject.body - Body string
 * @param {Array.<{search: String, Component: Object, children: Boolean}>} parseObject.list - Array of objects to search and replace
 * @returns {Boolean, Array.<String|JSX>}
 */
export function parsingFunctionArray({ body, list }) {
  let found = false;
  let replacedBody = body

  if (typeof window === 'undefined') {
    return ({ found: false, replacedBody });
  } 
  if (list.length > 0) {
    list.forEach((item) => {
      let Component = item.Component;

      if (Array.isArray(replacedBody)) {
        replacedBody = replacedBody.map((b) => {
          if (item.children) {
            return replaceShortcodeWithChildren(b, item, Component)
          } else {
            return replaceShortcodeNoChildren(b, item, Component)
          }
        })
        replacedBody = [].concat(...replacedBody);
      } else {
        if (item.children) {
          replacedBody = replaceShortcodeWithChildren(replacedBody, item, Component)
        } else {
          replacedBody = replaceShortcodeNoChildren(replacedBody, item, Component)
        }
      }
    });
  }

  // reactStringReplace will always give an array of one item if no replacement is made
  if (replacedBody.length > 1) {
    found = true;
  }

  return { found, replacedBody };
}

const replaceShortcodeWithChildren = (replacedBody, item, Component) => {
  return reactStringReplace(
    replacedBody,
    new RegExp(`&lt;${item.search}&gt;(.*)?&lt;\/${item.search}&gt;`),
    (match, i) => <Component key={match + i}>{match}</Component>
  );
}

const replaceShortcodeNoChildren = (replacedBody, item, Component) => {
  if (typeof replacedBody === 'string' || replacedBody instanceof String) {
    return replaceJSX(replacedBody, `&lt;${item.search}\/&gt;`, <Component key={`ccustom_${item.search}`} />);
  }
  return replacedBody;
}

const replaceShortcodeNoChildrenOld = (replacedBody, item, Component) => {
  return reactStringReplace(
    replacedBody,
    new RegExp(`&lt;${item.search}\/&gt;`),
    (match, i) => <Component key={`ccustom_${i}`} />
  );
}

const replaceJSX = (string, find, replace) => {
  if (String(string).split(find).length > 1) { 
    let stringParts = String(string).split(find);
    stringParts.splice(1, 0, replace);
    return stringParts
  }
  return string;
}