import React, { useState } from 'react';
import Webform from './Webform';
import Modal from './Modal';
import LoginFlow from './LoginFlow';
import JoinMailingList from './JoinMailingList';
import { RiShieldUserLine } from 'react-icons/ri';
import { MdOutlineRoomService, MdOutlineEmail } from 'react-icons/md';

const Contact = ({ bodyContent = '' }) => {
  const [joinMailingModalActive, setJoinMailingModalActive] = useState(false);
  const [form, setForm] = useState(false);

  const toggleEstimateFormModal = (e) => {
    setForm(!form);
  };

  const toggleJoinMailingModal = (e) => {
    setJoinMailingModalActive(!joinMailingModalActive);
  };

  return (
    <>
      <div className="contact-cta-items grid-3">
        <div className="item">
          <MdOutlineEmail />
          <h4>Mailing list</h4>
          <button
            className="button btn-outline"
            onClick={toggleJoinMailingModal}
          >
            Sign up
          </button>
          <Modal
            active={joinMailingModalActive}
            onClose={toggleJoinMailingModal}
          >
            <JoinMailingList className="join-mailing-list" />
          </Modal>
        </div>
        <div className="item">
          <RiShieldUserLine />
          <h4>Member Account</h4>
          <LoginFlow className="button btn-outline">Sign in</LoginFlow>
        </div>
        <div className="item">
          <MdOutlineRoomService />
          <h4>Free Estimate</h4>
          <button
            className="button btn-outline"
            onClick={toggleEstimateFormModal}
          >
            Fill form
          </button>
          <Modal active={form} onClose={() => toggleEstimateFormModal()}>
            <Webform name="Get a Free Estimate" />
          </Modal>
        </div>
      </div>
      <div className="contact grid-1">
        <div className="content t-center">
          <span dangerouslySetInnerHTML={{ __html: bodyContent }}></span>
        </div>
        <div className="form-wrapper">
          <Webform name="Contact" />
        </div>
      </div>
    </>
  );
};

export default Contact;
